import {Controller} from "stimulus";
import Fetch from "../modules/fetch"

export default class extends Controller {
  initialize() {
    new Fetch(this.url, (response) => {
      this.element.setAttribute('content', response.data);
    }, { method: 'POST'});
  }

  get url() {
    return '/forms/csrf_tokens';
  }
}