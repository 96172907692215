import SlideshowController from "./slideshow_controller"

export default class extends SlideshowController {
  static targets = ["marquee"]
  observe() {
    let observer = new MutationObserver(mutations => {
      this.setMarquee(mutations[0]);
    });
    observer.observe(this.element, {attributes: true, attributeFilter: ['data-marquee']});
  }

  setMarquee() {
    if (this.hasMarqueeTarget) {
      this.updateMarquee();
    }
    else {
      this.createMarquee();
    }
  }

  createMarquee() {
    let slide = this.swiper.slides[0].cloneNode(true);
    slide.setAttribute('class', 'responsive-wrapper swiper-slide')
    slide.setAttribute('data-target', 'product-slideshow.marquee')
    slide.removeAttribute('data-swiper-slide-index');
    let img = slide.querySelector('img');
    img.classList.remove('swiper-lazy-loaded');
    img.removeAttribute('srcset');
    img.setAttribute('data-srcset', this.element.dataset.marquee);
    this.swiper.prependSlide(slide);
    this.swiper.update();
    this.swiper.slideToLoop(0);
  }

  updateMarquee() {
    this.swiper.slideToLoop(1, 0, false);
    this.marqueeTargets.forEach(marquee => {
      let img = marquee.querySelector('img');
      img.classList.remove('swiper-lazy-loaded');
      img.removeAttribute('srcset');
      img.setAttribute('data-srcset', this.element.dataset.marquee);
    })
    this.swiper.update();
    this.swiper.slideToLoop(0);
  }
}