import {Controller} from "stimulus"
import * as dayjs from 'dayjs'
//import RelativeTime from 'dayjs/plugin/relativeTime'


export default class extends Controller {
  static targets = ["status", "description"];
  static values = {
    hours: Array,
    labels: Object,
    formats: Object
  }

  connect() {
    this.now = dayjs();
    this.update();
  }

  disconnect() {
    window.clearTimeout(this.timeout);
  }

  update() {
    if (this.isOpen) {
      this.setOpen();
    }
    else {
      this.setClosed();
    }
    //this.scheduleUpdate();
  }

  scheduleUpdate() {
    window.clearTimeout(this.timeout);
    let secondsUntilNextHour = 3600 - this.now.minute() * 60 - this.now.second();
    console.log(secondsUntilNextHour);
    this.timeout = window.setTimeout(this.update.bind(this), secondsUntilNextHour);
  }

  setOpen() {
    this.status = this.openLabel;
    this.description = this.nextCloseTime;
  }

  setClosed() {
    this.status = this.closedLabel;
    this.description = this.nextOpenTime;
  }

  get nextOpenTime() {
    for (let i = 0; i < 7; i++) {
      let currentDay = this.now.add(i, 'day');
      if (this.hoursValue[currentDay.day()]) {
        console.log('has hours for day', currentDay.day());
        for (let j = 0; j < this.hoursValue[currentDay.day()].length; j++) {
          let openTime = this.todayAtTime(this.hoursValue[currentDay.day()][j][0]).add(i, 'day');
          console.log('openTime', openTime.format('h:mm A'));
          if (this.now.isBefore(openTime)) {
            console.log('now is before openTime');
            if (currentDay.isSame(this.now, 'day')) {
              return `${this.opensDescription} ${openTime.format(this.timeFormat)}`
            }
            return `${this.opensDescription} ${openTime.format(this.timeFormatWithDay)}`
          }
        }
      }
    }
    return 'Closed Indefinitely'
  }

  get nextCloseTime() {
    let closeTime = null;
    this.currentSchedule.forEach(time => {
      if (this.now.isBefore(this.todayAtTime(time[1]))) {
        closeTime = this.todayAtTime(time[1]);
      }
    });
    if (closeTime === null) {
      closeTime = this.todayAtTime(this.latestClose).add(1, 'day');
    }
    return `${this.closesDescription} ${closeTime.format(this.timeFormat)}`
  }

  get isOpen() {
    let is_open = false;
    if (this.hasSchedule) {
      this.currentSchedule.forEach(time => {
        let openTime = this.todayAtTime(time[0]);
        let closeTime = this.todayAtTime(time[1]);
        if (closeTime.isBefore(openTime)) {
          closeTime = closeTime.add(1, 'day');
        }
        if (this.now.isAfter(openTime) && this.now.isBefore(closeTime)) {
          is_open = true;
        }
      });
    }
    return is_open;
  }

  todayAtTime(time) {
    let hour = Number(time.split(':')[0]);
    let minute = Number(time.split(':')[1]);
    return dayjs().hour(hour).minute(minute);
  }

  get latestClose() {
    return this.currentSchedule[this.currentSchedule.length - 1][1];
  }

  get currentSchedule() {
    return this.hoursValue[this.now.day()]
  }

  get hasSchedule() {
    return this.currentSchedule !== undefined;
  }

  set status(status) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = status;
    }
  }

  set description(description) {
    if (this.hasDescriptionTarget) {
      this.descriptionTarget.textContent = description;
    }
  }

  get closesDescription() {
    if (this.hasLabelsValue) {
      return this.labelsValue.closes_at || 'Closes at';
    }
    return 'Closes';
  }

  get opensDescription() {
    if (this.hasLabelsValue) {
      return this.labelsValue.opens_at || 'Opens at';
    }
    return 'Opens';
  }

  get closedLabel() {
    if (this.hasLabelsValue) {
      return this.labelsValue.closed || 'Closed';
    }
    return 'Closed';
  }

  get openLabel() {
    if (this.hasLabelsValue) {
      return this.labelsValue.open || 'Open';
    }
    return 'Open';
  }

  get timeFormat() {
    if (this.hasFormatsValue) {
      return this.formatsValue.time || '[at] h:mm A';
    }
    return '[at] h:mm A';
  }

  get timeFormatWithDay() {
    if (this.hasFormatsValue) {
      return this.formatsValue.time_with_day || '[at] h:mm A dddd';
    }
    return '[at] h:mm A dddd';
  }

}