import {Controller} from "stimulus";
import {createPopper} from '@popperjs/core';

export default class extends Controller {
  static targets = ['button', 'content']

  initialize() {
    this.popper = null;
  }

  connect() {
  }

  open(event) {
    event.stopPropagation();
    document.dispatchEvent(new CustomEvent("md.tooltip.close"));
    this.pop();
    this.contentTarget.classList.toggle('open', true);
    this.contentTarget.style.display = 'block';
    this.startCloseEventListener(this.element);
  }

  toggle(event) {
    event.stopPropagation();
    if (this.contentTarget.classList.contains('open')) {
      this.close();
    } else {
      this.open(event);
    }
  }

  startCloseEventListener(element) {
    const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

    const outsideClickListener = event => {
      if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
        this.close();
        removeClickListener()
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener)
      document.removeEventListener('md.tooltip.close', outsideClickListener)
    };

    document.addEventListener('click', outsideClickListener)
    document.addEventListener('md.tooltip.close', outsideClickListener)
  }


  pop() {
    this.popper = createPopper(this.reference, this.contentTarget, {
      placement: this.placement,
      modifiers: this.modifiers
    });
  }

  get reference() {
    if (this.hasButtonTarget) {
      return this.buttonTarget;
    }
    return this.element;
  }

  close() {
    if (this.popper != null) {
      this.contentTarget.classList.toggle('open', false);
      this.contentTarget.style.display = 'none';
      this.popper.destroy();
      this.popper = null;
    }
  }

  get placement() {
    let placement = this.data.get('placement');
    if (placement == null) {
      return 'bottom';
    }
    return placement;
  }

  get modifiers() {
    if (this.offset === 0) {
      return [];
    }
    return [{name: 'offset', options: {offset: [this.offsetSkid, this.offset]}}]
  }

  get offset() {
    let offset = this.data.get('offset');
    if (offset == null) {
      return 0;
    }
    return offset;
  }

  get offsetSkid() {
    let offset = this.data.get('offset-skid');
    if (offset == null) {
      return 0;
    }
    return parseInt(offset);
  }

}