export default class Fetch {
  constructor(url, callback, options) {
    this.url = url;
    this.callback = callback;
    this.options = options || {};
    this.perform();
  }

  get csrf_token() {
    try {
      return document.head.querySelector("[name=csrf-token]").content;
    }
    catch(ex) {
      return '';
    }
  }

  get fetch_headers() {
    let headers = {
      'Accept': 'application/json, text/html',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    if (!this.isGetRequest) {
      headers['X-CSRF-Token'] = this.csrf_token
    }
    return headers;
  }

  get isGetRequest() {
    return (this.method === 'GET');
  }

  get isJSON() {
    return this.options.type === 'JSON';
  }

  get method() {
    if (this.isValidMethod(this.options.method)) {
      return this.options.method;
    }
    return 'GET';
  }

  get body() {
    return this.options.body;
  }

  isValidMethod(method) {
    return (['GET', 'POST', 'PUT', 'PATCH', 'OPTIONS', 'DELETE'].indexOf(method) > -1)
  }

  perform() {
    let init = {method: this.method, headers: this.fetch_headers};
    if (this.body != null && !this.isGetRequest) {
      init.body = JSON.stringify(this.body);
    }
    fetch(this.url, init)
      .then(this.fetch_response.bind(this))
      .then(this.compose_response.bind(this))
      .catch(this.compose_error.bind(this));
  }

  compose_response(value) {
    this.callback({status:this.responseStatus.code, statusText:this.responseStatus.text, data:value, error:false});
  }

  compose_error(error) {
    this.callback({status: 400, response: error, error:true});
  }

  fetch_response(response) {
    this.responseStatus = {code:response.status, text:response.statusText};
    if (!response.ok) {
      if (response.headers.get('message') != null) {
        throw Error(response.headers.get('message'));
      } else {
        throw Error(response.statusText);
      }
    }
    if (this.isJSON) {
      return response.json();
    }
    return response.text();
  }

}