import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["content"];

  initialize() {
    this.originalStyle = this.container.getAttribute('style');
    this.canSetHeight = (this.element.style.height === '');

    this.calculatedOffsets = {};
    this.ticking = false;
    this.hidden = false;

    this.setFrameDimensions();

    this.should_contain = this.data.get('contain');
    this.boundEventListener = this.onScroll.bind(this);
    this.pinnedEventListener = this.onPinned.bind(this);
    this.boundReset = this.reset.bind(this);

    let observer = new MutationObserver(mutations => {
      this.setFrameDimensions();
    });
    this.container.addEventListener('transitionend', () => {
      this.setFrameDimensions();
    });
    observer.observe(this.container, {attributes:true});
    this.onScroll();
  }

  connect() {
    window.addEventListener('scroll', this.boundEventListener);
    window.addEventListener('resize', this.boundReset);
    document.addEventListener('md.element.pinned', this.pinnedEventListener);
    document.addEventListener('md.element.unpinned', this.pinnedEventListener);
  }

  disconnect() {
    window.removeEventListener('scroll', this.boundEventListener);
    window.removeEventListener('resize', this.boundReset);
    document.removeEventListener('md.element.pinned', this.pinnedEventListener);
    document.addEventListener('md.element.unpinned', this.pinnedEventListener);
  }

  setFrameDimensions() {
    let dimensions = this.element.getBoundingClientRect();
    if (this.element.style.height === '') {
      this.element.style.height = dimensions.height + 'px';
    }
    if (dimensions.height === 0) {
      this.hidden = true;
    }
    else {
      this.hidden = false;
    }
  }

  reset() {
    if (this.canSetHeight) {
      this.element.style.height = '';
    }
    this.unpin();
    this.hidden = false;
    this.offsetPositionCache = null;
    this.maxCache = null;
    this.ticking = false;
    this.setFrameDimensions();
    this.onScroll();
  }

  pin() {
    if (!this.pinned) {
      if (!this.hidden) {
        this.firePinnedEvent('md.element.pinned');
      }
      this.container.classList.add('dm-fixed');
      this.container.style.width = this.element.offsetWidth + 'px';
      this.container.style.top = this.offset + 'px';
      this.pinned = true;
    }
    else {
      this.contain();
    }
  }

  updateTop() {
    if (this.pinned) {
      this.container.style.top = this.offset + 'px';
    }
  }

  contain() {
    if (this.should_contain) {
      if ((window.scrollY + this.offset) > this.max) {
        this.container.classList.remove('dm-fixed');
        this.container.style.position = 'absolute';
        this.container.style.top = this.containPosition + 'px';
        this.contained = true;
      }
      else {
        if (this.contained) {
          this.container.classList.add('dm-fixed');
          this.container.style.top = this.offset + 'px';
          this.contained = false;
        }
      }
    }
  }

  unpin() {
    if (this.pinned) {
      if (!this.hidden) {
        this.firePinnedEvent('md.element.unpinned');
      }
      this.restoreOriginalStyle();
      this.pinned = false;
    }
  }

  restoreOriginalStyle() {
    this.container.classList.remove('dm-fixed');
    if (this.originalStyle != null) {
      this.container.setAttribute('style', this.originalStyle);
    } else {
      this.container.removeAttribute('style');
    }
  }

  onScroll(event) {
    this.scrollPosition = window.scrollY;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        if (this.scrollPosition >= this.offsetPosition) {
          this.pin();
        }
        else {
          this.unpin();
        }
        this.ticking = false;
      });
      this.ticking = true;
    }
  }

  firePinnedEvent(eventName) {
    document.dispatchEvent(new CustomEvent(eventName, {
      detail: {
        element: this.container
      }
    }));
  }

  onPinned(event) {
    let element = event.detail.element;
    if (element !== this.container) {
      if (event.type === 'md.element.pinned') {
        if (element.compareDocumentPosition(this.element) & Node.DOCUMENT_POSITION_FOLLOWING) {
          this.calculatedOffsets[element.id] = element.getBoundingClientRect().height;
        }
      }
      else {
        this.calculatedOffsets[element.id] = 0;
      }
      this.updateTop();
    }
  }

  get container() {
    return this.contentTarget || this.element;
  }

  get top() {
    let elementTop = this.container.getBoundingClientRect().top;
    return elementTop + (window.pageYOffset || document.documentElement.scrollTop);
  }

  get offsetPosition() {
    if (this.offsetPositionCache == null) {
      this.offsetPositionCache = this.top - this.offset;
    }
    return this.offsetPositionCache;
  }

  get offset() {
    let offset = this.data.get('offset');
    if (offset == null) {
      offset = 0;
      Object.keys(this.calculatedOffsets).forEach(key => {
        offset += this.calculatedOffsets[key];
      });
      return offset;
    }
    return parseInt(offset);
  }

  get max() {
    if (this.maxCache == null) {
      this.containOffset = this.element.parentNode.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop);
      this.maxCache = ((this.containOffset + this.element.parentNode.offsetHeight) - this.element.offsetHeight);
    }
    return this.maxCache;
  }

  get containPosition() {
    return this.max - this.containOffset;
  }
}