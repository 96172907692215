import {Controller} from "stimulus";

export default class extends Controller {

  initialize() {
    this.boundScroll = this.scrollTo.bind(this);
  }

  connect() {
    document.querySelectorAll("a[href^='#']").forEach(link => {
      link.addEventListener('click', this.boundScroll);
    });
  }

  scrollTo(event) {
    let target = document.querySelector(event.currentTarget.getAttribute('href'));
    if (target) {
      event.preventDefault();
      target.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }
}