import {Controller} from "stimulus"
import {Swiper, Lazy, Pagination, Navigation, Autoplay, Keyboard, Mousewheel} from 'swiper';

//Swiper.use([EffectCube]);

export default class extends Controller {
  static targets = ["container", "pager", "thumbnail", "next", "prev"];

  initialize() {
    Swiper.use(this.modules)
    this.boundShow = this.show.bind(this);
    this.swiper = new Swiper(this.container, this.options);
    this.swiper.on('slideChange', this.activateThumbnail.bind(this));
    this.activateThumbnail();
    if (this.startingIndex > 0) {
      this.index = this.startingIndex;
    }
    this.observe();
  }

  observe() {

  }

  connect() {
    this.element.addEventListener('md.slideshow.slide', this.boundShow);
  }

  disconnect() {
    this.element.removeEventListener('md.slideshow.slide', this.boundShow);
  }

  show(event) {
    if (event.detail.index != null) {
      console.log('slideshow got show event with index of: ' + event.detail.index)
      this.index = event.detail.index
    }
    else {
      this.index = this.thumbnailTargets.indexOf(event.currentTarget)
    }
  }

  activateThumbnail() {
    this.thumbnailTargets.forEach((thumb, index) => {
      thumb.classList.toggle(this.activeClass, index == this.swiper.realIndex);
    })
  }

  nextSlide(event) {
    this.index = this.swiper.realIndex + 1;
    this.activateThumbnail();
  }

  previousSlide(event) {
    this.index = this.swiper.realIndex - 1;
    this.activateThumbnail();
  }

  get options() {
    let options = {direction: 'horizontal', loop: true}
    if (this.isLazy) {
      options.lazy = true;
      options.preloadImages = false;
    }
    if (this.autoHeight) {
      options.autoHeight = true;
    }
    if (this.keyboard) {
      options.keyboard = {
        enabled: true,
        onlyInViewport: true
      }
    }
    if (this.mouse) {
      options.mousewheel = true
    }
    if (this.hasPagerTarget) {
      options.pagination = {
        el: this.pagerTarget,
        bulletClass: 'pager-bullet',
        bulletActiveClass: 'pager-bullet-active',
        clickable: true
      }
    }
    if (this.hasNextTarget || this.hasPrevTarget) {
      options.navigation = {nextEl: this.next, prevEl: this.prev}
    }
    if (this.hasInterval) {
      options.autoplay = {delay: this.interval}
    }
    return options;
  }

  get next() {
    if (this.hasNextTarget) {
      return this.nextTarget;
    }
    return null;
  }

  get prev() {
    if (this.hasPrevTarget) {
      return this.prevTarget;
    }
    return null;
  }

  get container() {
    if (this.hasContainerTarget) {
      return this.containerTarget;
    }
    return this.element;
  }

  get modules() {
    let modules = [Lazy, Pagination, Navigation, Autoplay];
    if (this.keyboard) {
      modules.push(Keyboard);
    }
    if (this.mouse) {
      modules.push(Mousewheel);
    }
    return modules;
  }

  get isLazy() {
    return this.data.get('lazy') === 'true';
  }

  get keyboard() {
    return this.data.get('keyboard') === 'true';
  }

  get mouse() {
    return this.data.get('mouse') === 'true';
  }

  get autoHeight() {
    return this.data.get('autoheight') === 'true'
  }

  get activeClass() {
    return this.data.get('active-class') || 'active'
  }

  get hasInterval() {
    return this.interval > 0;
  }

  get interval() {
    try {
      let interval = parseInt(this.data.get('interval'));
      if (isNaN(interval)) {
        return 0;
      }
      return interval;
    } catch {
      return 0;
    }
  }

  get startingIndex() {
    try {
      let index = parseInt(this.data.get('index'));
      if (isNaN(index)) {
        return 0;
      }
      return index;
    } catch {
      return 0;
    }
  }

  set index(value) {
    this.swiper.slideToLoop(value);
  }
}