import ProductButtonController from "./product_button_controller"

export default class extends ProductButtonController {
  static targets = ['container', 'quantity', 'banner']
  activateControl(transition = true) {
    if (!this.hasQuantityTarget) {
      this.createBanner();
    }
    this.quantityTarget.textContent = this.currentQuantity;
    this.bannerTarget.style.display = 'block';
  }

  deactivateControl() {
    if (this.hasBannerTarget) {
      this.bannerTarget.style.display = 'none';
    }
  }

  createBanner() {
    let banner = document.createElement('a');
    banner.setAttribute('href', '/cart');
    banner.setAttribute('class', 'incartbanner');
    banner.setAttribute('data-target', 'list-cart.banner');
    banner.innerHTML = '<span data-target="list-cart.quantity"></span> ' + this.cartLabel
    this.containerTarget.appendChild(banner);
  }
}