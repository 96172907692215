import {Controller} from "stimulus";
import Modal from "../modules/modal"

export default class extends Controller {
  static targets = ['button', 'removeControl', 'addControl', 'overlay']

  initialize() {
    if (this.hasButtonTarget) {
      this.originalText = this.buttonTarget.innerHTML;
    }
    this.currentQuantity = 0;
    this.boundListen = this.cartListen.bind(this);
    this.initShoppingCartQuery();
  }

  connect() {
    document.addEventListener('md.cart.modified', this.boundListen);
  }

  disconnect() {
    document.removeEventListener('md.cart.modified', this.boundListen);
  }

  initShoppingCartQuery() {
    this.cartResponded = false;
    this.boundCartResponse = this.cartResponse.bind(this);
    this.element.addEventListener('md.cart.response', this.boundCartResponse);
    this.queryCart();
  }

  showVariantModal() {
    if (this.modal != null) {
      this.modal.show();
    }
    else {
      this.modal = new Modal({css: 'product-modal', url:this.data.get('url')});
    }
  }

  add(event) {
    event.preventDefault();
    if (this.hasVariants) {
      this.showVariantModal();
    }
    else {
      this.addToCart(this.sku);
    }
  }

  addToCart(sku) {
    document.dispatchEvent(new CustomEvent('md.cart.add', {
      detail: {id:sku, quantity:1}
    }));
  }

  remove(event) {
    document.dispatchEvent(new CustomEvent('md.cart.remove', {
      detail: {id: this.sku, quantity: 1}
    }));
  }

  cartListen(event) {
    if (this.modal != null) {
      this.modal.hide();
    }
    if (this.hasVariants) {
      if (this.sku.indexOf(event.detail.sku) > -1) {
        this.cartResponded = false;
        this.queryCart();
      }
    }
    else {
      if (this.sku === event.detail.sku) {
        this.currentQuantity = parseInt(event.detail.quantity);
        if (this.currentQuantity > 0) {
          this.activateControl();
        }
        else {
          this.deactivateControl();
        }
      }
    }
  }

  queryCart() {
    if (!this.cartResponded) {
      document.dispatchEvent(new CustomEvent('md.cart.query', {
        detail: {
          element: this.element,
          sku: this.sku
        }
      }));
      setTimeout(this.queryCart.bind(this), 100);
    }
  }

  cartResponse(event) {
    this.cartResponded = true;
    this.currentQuantity = event.detail;
    if (event.detail > 0) {
      this.activateControl(false);
    } else {
      this.deactivateControl();
    }
  }

  activateControl(transition = true) {
    if (!transition) {
      this.element.classList.add('no-transition');
    }
    this.element.classList.add('active');
    this.setCartLink();
  }

  deactivateControl() {
    this.element.classList.remove('active');
    this.removeCartLink();
  }

  setCartLink() {
    this.buttonTarget.innerHTML = '<a href="/cart">' + this.currentQuantity + ' ' + this.cartLabel + '</a>'
    this.buttonTarget.removeAttribute('data-action');
  }

  removeCartLink() {
    this.buttonTarget.innerHTML = this.originalText;
    this.buttonTarget.setAttribute('data-action', 'click->product-button#add');
  }

  get sku() {
    if (this.skuCache == null) {
      this.skuCache = JSON.parse(this.data.get('id'));
    }
    return this.skuCache;
  }

  get cartLabel() {
    return this.data.get('cart-label');
  }

  get hasVariants() {
    return typeof(this.sku) === 'object';
  }

}