import {Controller} from "stimulus";
import Fetch from "../modules/fetch"

export default class extends Controller {
  static targets = ['form', 'message']
  static values = {
    id: String,
    redirect: String
  }

  submit(event) {
    event.preventDefault();
    if (this.formTarget.reportValidity()) {
      new Fetch(this.url, this.callback.bind(this), {
        method: 'POST',
        body: this.formData
      });
    }
  }

  callback(response) {
    if (response.status === 200) {
      if (this.redirect != null && this.redirect !== '') {
        window.location.href = this.redirect;
      } else {
        this.formTarget.style.display = 'none';
        this.messageTarget.style.display = 'block';
      }
    }
    else {
      alert(response.response)
    }
  }

  get formData() {
    let data = new FormData(this.formTarget);
    let response = {}
    for (var key of data.keys()) {
      response[key] = data.get(key);
    }
    return response;
  }

  get url() {
    return '/legacy_forms/' + this.idValue + '/responses';
  }

  get redirect() {
    return this.redirectValue;
  }
}