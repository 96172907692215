import {Controller} from "stimulus";

export default class extends Controller {

  initialize() {


  }

  toggle(event) {
    if (this.toggleSelf) {
      this.element.classList.toggle(this.css);
    }
    this.targets.forEach(element => {
      element.classList.toggle(this.css);
    })
    this.setFocus();
  }

  remove() {
    if (this.toggleSelf) {
      this.element.classList.remove(this.css);
    }
    this.targets.forEach(element => {
      element.classList.remove(this.css);
    })
  }

  setFocus() {
    try {
      if (this.data.get('focus') != null && this.data.get('focus') !== '') {
        let element = document.querySelector(this.data.get('focus'));
        element.focus();
      }
    }
    catch {}
  }

  get targets() {
    return document.querySelectorAll(this.target);
  }

  get target() {
    return this.data.get('target') || this.element.dataset.target;
  }

  get css() {
    return this.data.get('class') || 'open'
  }

  get toggleSelf() {
    return this.data.get('self') === 'true';
  }
}