export default class Modal {
  constructor(options) {
    this.options = options || {};
    this.createModal();
  }

  show() {
    this.element.dispatchEvent(new CustomEvent('md.modal.show'));
  }

  hide() {
    this.element.dispatchEvent(new CustomEvent('md.modal.hide'));
  }

  createModal() {
    let modal = document.createElement('div');
    modal.setAttribute('data-controller', 'modal');
    modal.setAttribute('data-modal-single-load-value', 'true');
    if (this.options.url != null) {
      modal.setAttribute('data-modal-url-value', this.options.url);
    }
    if (this.options.content != null) {
      modal.innerHTML = '<template data-modal-target="template">' + this.options.content + '</template>';
    }
    if (this.options.css != null) {
      modal.setAttribute('data-modal-css-value', this.options.css);
    }
    if (this.options.data != null) {
      for (let key in this.options.data) {
        modal.setAttribute('data-' + key, this.options.data[key]);
      }
    }
    this.target.appendChild(modal);
    this.element = modal;
  }

  get target() {
    return this.specifiedTarget || document.body;
  }

  get specifiedTarget() {
    if (this.options.target == null) {
      return null;
    }
    if (this.options.target instanceof Element) {
      return this.options.target;
    }
    return document.querySelector(this.options.target);
  }

}