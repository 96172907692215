export default class ShoppingCart {
  constructor(element) {
    this.element = element;
    this.load();
  }

  totalQuantity() {
    return this.cart.quantity;
  }

  quantityForSku(sku) {
    if (typeof(sku) === 'object') {
      return this.quantityForSkus(sku);
    }
    return this.skus[sku] || 0;
  }

  quantityForSkus(skus) {
    let quantity = 0;
    skus.forEach(sku => {
      quantity += this.quantityForSku(sku);
    });
    return quantity;
  }

  quantityForItem(itemId) {
    return this.cart.items[itemId].quantity
  }

  itemsForSku(sku) {
    let items = this.skuItems[sku];
    if (items == null) {
      return []
    }
    return items;
  }

  itemsForMultipleSkus(skus) {
    let items = [];
    skus.forEach(sku => {
      items = items.concat(this.itemsForSku(sku));
    })
    return items;
  }

  addCallback() {
    return this.add.bind(this);
  }

  updateCallback() {
    return this.update.bind(this);
  }

  removeCallback() {
    return this.remove.bind(this);
  }

  add(response) {
    if (response.status === 200) {
      let item = response.data;
      if (this.cart.items[item.id] == null) {
        this.cart.items[item.id] = {sku: item.sku, quantity: item.quantity}
        this.cart.quantity += item.quantity;
      } else {
        let difference = (item.quantity - this.cart.items[item.id].quantity)
        this.cart.items[item.id].quantity = item.quantity;
        this.cart.quantity += difference;
      }
      this.save();
      this.loadSkus();
      this.sendEvent('md.cart.modified', item);
    }
  }

  update(response) {
    if (response.status === 200) {
      let summary = response.data;
      this.cart.items[summary.item].quantity = summary.item_qty
      this.calculateQuantity();
      this.save();
      this.loadSkus();
      this.sendEvent('md.cart.modified', this.itemWithId(summary.item));
      this.sendEvent('md.cart.summary', summary);
    }
  }

  remove(response) {
    if (response.status === 200) {
      let summary = JSON.parse(response.data);
      let sku = this.cart.items[summary.item].sku;
      delete this.cart.items[summary.item];
      this.calculateQuantity();
      this.save();
      this.loadSkus();
      this.skuItemsCache = null;
      this.sendEvent('md.cart.modified', {id:summary.item, sku:sku, quantity:0});
      this.sendEvent('md.cart.summary', summary);
    }
  }

  calculateQuantity() {
    try {
      let quantity = 0;
      Object.values(this.cart.items).forEach(item => {
        quantity += item.quantity;
      });
      this.cart.quantity = quantity;
    } catch(ex) {
      console.log('Shopping cart quantity error: ' + ex.message);
    }

  }

  save() {
    this.element.content = JSON.stringify(this.cart);
  }

  load() {
    try {
      this.cart = JSON.parse(this.element.content);
      this.loadSkus();
    } catch (ex) {
      this.cart = {};
      console.log('Shopping cart initialization error: ' + ex.message);
    }
  }

  loadSkus() {
    this.skus = {};
    Object.values(this.cart.items).forEach(item => {
      if (this.skus[item.sku] == null) {
        this.skus[item.sku] = item.quantity;
      } else {
        this.skus[item.sku] += item.quantity;
      }
    });
  }

  itemWithId(id) {
    let item = this.cart.items[id];
    item['id'] = id;
    return item;
  }

  get skuItems() {
    if (this.skuItemsCache == null) {
      this.skuItemsCache = {};
      Object.keys(this.cart.items).forEach(itemId => {
        let sku = this.cart.items[itemId].sku;
        if (this.skuItemsCache[sku] == null) {
          this.skuItemsCache[sku] = [itemId];
        }
        else {
          this.skuItemsCache[sku].push(itemId);
        }
      })
    }
    return this.skuItemsCache;
  }

  sendEvent(event, detail) {
    document.dispatchEvent(new CustomEvent(event, {
      detail: detail
    }));
  }


}