import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['variant', 'thumbnail', 'marqueeThumb', 'slideshow', 'sku', 'form', 'input', 'quantity', 'price', 'button', 'error']

  initialize() {
    this.unpackSkus();
    this.observeVariants();
    this.sendAllowedOptions();
  }

  isVowel (string) {
    return !!string.charAt(0).match(/[aeiou]/i)
  }

  observeVariants() {
    let observer = new MutationObserver(mutations => {
      this.variantSelected(mutations[0]);
    });
    this.variantTargets.forEach(variant => {
      observer.observe(variant, {attributes: true, attributeFilter: ['data-selected']});
    })
  }

  unpackSkus() {
    this.skus = {};
    this.options = {}
    this.skuTargets.forEach(sku => {
      try {
        let options = JSON.parse(sku.dataset.options);
        Object.values(options).forEach(option => {
          (this.skus[option] = this.skus[option] || []).push(sku.value);
          (this.options[sku.value] = this.options[sku.value] || []).push(option);
        })
      }
      catch(ex) {
        console.log('Error getting options for SKU: ' + ex.message);
      }
    })
  }


  variantSelected(mutation) {
    this.hideError();
    if (this.allVariantsSelected()) {
      this.setSku();
    }
    this.sendAllowedOptions(mutation);
  }

  sendAllowedOptions(mutation) {
    this.currentSelection = mutation ? mutation.target : null;
    let options = this.availableOptions;
    this.variantTargets.forEach(variant => {
      if (variant !== this.currentSelection) {
        variant.setAttribute('data-variant-available', options);
      }
    })
    this.promptSelect();
  }

  hideError() {
    this.errorTarget.style.display = 'none';
    this.errorTarget.textContent = '';
  }

  showError(error) {
    this.errorTarget.innerText = error;
    this.errorTarget.style.display = 'block';
  }

  variantSelectTooltip(event) {
    event.preventDefault();
    let variant = this.unselectedVariant;
    let article = this.isVowel(variant.dataset.name) ? 'an' : 'a';
    this.showError(this.data.get('select').replace('{variant}', variant.dataset.name).replace('{article}', article));
  }

  promptSelect() {
    let variant = this.unselectedVariant;
    if (variant != null) {
      this.buttonTarget.setAttribute('data-action', 'product-variant#variantSelectTooltip');
    }
  }

  quickAdd() {
    let sku = this.inputTarget.value;
    if (sku !== '') {
      document.dispatchEvent(new CustomEvent('md.cart.add', {
        detail: {id:sku, quantity: this.quantityTarget.value}
      }));
    }
  }

  setSku() {
    let sku = this.selectedSku;
    if (sku == null) {
      this.buttonTarget.classList.add('unavailable');
      this.buttonTarget.disabled = true;
      this.buttonTarget.innerText = this.data.get('unavailable');
    }
    else {
      this.skuImage = sku
      this.quantity = sku.dataset.stock;
      this.priceTarget.innerText = sku.dataset.price;
      this.inputTarget.value = sku.value;
      this.buttonTarget.classList.remove('unavailable');
      this.buttonTarget.disabled = false;
      this.buttonTarget.innerText = this.data.get('add');
      if (this.data.get('quick')) {
        this.buttonTarget.setAttribute('data-action', 'product-variant#quickAdd');
      }
      else {
        this.buttonTarget.removeAttribute('data-action');
      }
    }
  }

  allVariantsSelected() {
    let value = true;
    this.variantTargets.forEach(variant => {
      if (variant.dataset.selected == null || variant.dataset.selected === '') {
        value = false;
      }
    });
    return value;
  }

  intersection(a, b) {
    var setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x));
  }

  get selectedSku() {
    let skus = this.selectedSkus(null);
    if (skus.length > 0) {
      return this.getSkuObject(skus[0]);
    }
    return null;
  }

  selectedSkus(selection) {
    if (selection != null) {
      return this.skus[this.currentSelection.dataset.selected] || []
    }
    let skus = this.allSkus;
    this.variantTargets.forEach(variant => {
      if (variant.dataset.selected) {
        skus = this.intersection(skus, this.skus[variant.dataset.selected]);
      }
    });
    return skus;
  }

  getSkuObject(id) {
    let sku;
    for (let target of this.skuTargets) {
      if (target.value === id) {
        sku = target;
        break;
      }
    }
    return sku;
  }

  get allSkus() {
    if (this.allSkusCache == null) {
      this.allSkusCache = [];
      this.skuTargets.forEach(sku => {
        this.allSkusCache.push(sku.value);
      });
    }
    return this.allSkusCache;
  }

  set skuImage(sku) {
    if (sku.dataset.image && this.slideshowTarget.dataset.marquee !== sku.dataset.image) {
      if (this.hasMarqueeThumbTarget) {
        let img = this.marqueeThumbTarget.querySelector('img');
        img.setAttribute('src', sku.dataset.thumb)
      }
      else {
        if (this.hasThumbnailTarget) {
          let thumbnail = this.thumbnailTargets[0].cloneNode(true);
          thumbnail.setAttribute('data-target', thumbnail.getAttribute('data-target') + ' product-variant.marqueeThumb');
          let img = thumbnail.querySelector('img');
          img.setAttribute('src', sku.dataset.thumb)
          this.thumbnailTargets[0].parentNode.insertBefore(thumbnail, this.thumbnailTargets[0]);
        }
      }
      this.slideshowTarget.dataset.marquee = sku.dataset.image;
    }
  }

  get availableOptions() {
    let options = [];
    this.selectedSkus(this.currentSelection).forEach(sku => {
      options = options.concat(this.options[sku]);
    });
    return [...new Set(options)];
  }

  get unselectedVariant() {
    let variant;
    for (let target of this.variantTargets) {
      if (target.dataset.selected == null || target.dataset.selected === '' ) {
        variant = target;
        break;
      }
    }
    return variant;
  }

  set quantity(inventory) {
    inventory = parseInt(inventory);
    let currentSelection = parseInt(this.quantityTarget.value);
    this.quantityTarget.innerHTML = '';
    for (let i = 1; i <= inventory; i++) {
      let option = document.createElement('option');
      option.value = i;
      option.textContent = i;
      this.quantityTarget.appendChild(option);
    }
    if (currentSelection > inventory) {
      this.quantityTarget.value = inventory;
    } else {
      this.quantityTarget.value = currentSelection;
    }
    return inventory;
  }


}