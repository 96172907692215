import {Controller} from "stimulus";
import Fetch from "../modules/fetch"
import ShoppingCart from "../modules/shopping_cart"

export default class extends Controller {
  static targets = ['itemLabel']
  static values = {
    primary: Boolean,
    singular: String,
    plural: String
  }

  initialize() {
    this.loadShoppingCart();
    if (this.primaryValue) {
      this.boundQuery = this.query.bind(this);
      this.boundAdd = this.add.bind(this);
      this.boundUpdate = this.update.bind(this);
      this.boundRemove = this.remove.bind(this);
    }
    else {
      this.boundCartUpdate = this.updateCart.bind(this);
    }
  }

  connect() {
    if (this.primaryValue) {
      document.addEventListener('md.cart.add', this.boundAdd);
      document.addEventListener('md.cart.update', this.boundUpdate);
      document.addEventListener('md.cart.remove', this.boundRemove);
      document.addEventListener('md.cart.query', this.boundQuery);
    }
    else {
      document.addEventListener('md.cart.modified', this.boundCartUpdate);
    }
  }

  disconnect() {
    if (this.primaryValue) {
      document.removeEventListener('md.cart.add', this.boundAdd);
      document.removeEventListener('md.cart.update', this.boundUpdate);
      document.removeEventListener('md.cart.remove', this.boundRemove);
      document.removeEventListener('md.cart.query', this.boundQuery);
    }
    else {
      document.removeEventListener('md.cart.modified', this.boundCartUpdate);
    }
  }

  add(event) {
    new Fetch(this.url, this.cart.addCallback(), {method: 'POST', body: {cart_item:{api_id: event.detail.id, quantity: event.detail.quantity}}, type:'JSON'});
  }

  update(event) {
    let items = this.itemIdsFromEvent(event);
    new Fetch(this.url + '/' + items[0], this.cart.updateCallback(), {
      method: 'PUT',
      type: 'JSON',
      body: {cart_item:{quantity: event.detail.quantity}}
    });
  }

  remove(event) {
    let items = this.itemIdsFromEvent(event);
    if (items.length == 0) {
      return true; //this is weird
    }
    if (items.length > 1) {
      window.location.href = '/cart';
      return;
    }
    let quantity = this.cart.quantityForItem(items[0]) - event.detail.quantity;
    if (quantity < 1) {
      new Fetch(this.url + '/' + items[0], this.cart.removeCallback(), {
        method: 'DELETE'
      });
    }
    else {
      new Fetch(this.url + '/' + items[0], this.cart.updateCallback(), {
        method: 'PUT',
        type: 'JSON',
        body: {cart_item: {quantity: quantity}}
      });
    }
  }

  fetchRemoveResponse(response) {
    if (response.status === 200) {
      this.currentQuantity = this.currentQuantity + this.addOperation.quantity;
      this.activateControl();
    }
  }

  updateCart() {
    this.cart.load();
    this.updateCartLabel();
  }

  query(event) {
    let element = event.detail.element;
    element.dispatchEvent(new CustomEvent('md.cart.response', {
      detail:this.cart.quantityForSku(event.detail.sku)
    }));
  }

  updateCartLabel() {
    if (this.hasItemLabelTarget) {
      let string = (this.cart.totalQuantity() === 1) ? this.singularValue : this.pluralValue;
      this.itemLabelTarget.innerHTML = '<span class="cart-link-item-count">' + this.cart.totalQuantity() + '</span> <span class="cart-link-item-text">' + string + '</span>';
    }
  }

  fetchCartData() {
    if (this.primaryValue) {
      new Fetch(this.metaDataUrl, this.insertCartData.bind(this));
    }
    else {
      this.listenForDataEvent();
    }
  }

  insertCartData(response) {
    if (response.status === 200) {
      let tempContainer = document.createElement('div');
      tempContainer.innerHTML = response.data;
      tempContainer.childNodes.forEach(meta => {
        if (meta.tagName === 'META') {
          document.head.appendChild(meta);
        }
      });
      document.dispatchEvent(new CustomEvent('md.cart.loaded'));
    }
  }

  loadShoppingCart() {
    if (this.hasMetaTags()) {
      this.cart = new ShoppingCart(this.cartTag, this.primaryValue);
      this.updateCartLabel();
    }
    else {
      this.fetchCartData();
    }
  }

  listenForDataEvent() {
    document.addEventListener('md.cart.loaded', event => {
      this.loadShoppingCart();
    });
  }

  hasMetaTags() {
    return document.head.querySelector("[name=shopping-cart]") != null
  }

  itemIdsFromEvent(event) {
    let items;
    if (event.detail.item == null) {
      items = (typeof (event.detail.id) === 'string') ? this.cart.itemsForSku(event.detail.id) : this.cart.itemsForMultipleSkus(event.detail.id);
    } else {
      items = [event.detail.item];
    }
    return items;
  }

  get url() {
    return '/cart/items';
  }

  get metaDataUrl() {
    return '/cart/shopping-cart-meta-data';
  }

  get itemCount() {
    return this.cart.totalQuantity();
  }

  get cartTag() {
    if (this.cartMetaTag == null) {
      this.cartMetaTag = document.head.querySelector("[name=shopping-cart]")
    }
    return this.cartMetaTag;
  }
}