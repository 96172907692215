// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import "@hotwired/turbo-rails"
import "@stimulus/polyfills"
import "classlist-polyfill"
import 'nodelist-foreach-polyfill';
import 'custom-event-polyfill';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.prepend';
import 'unfetch/polyfill';
import 'formdata-polyfill';
import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("madedaily", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

//const Turbolinks = require("turbolinks")
//Turbolinks.start()

//const Rails = require('@rails/ujs');
//Rails.start();
