import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ['label', 'option']

  initialize() {
    this.available();
    let observer = new MutationObserver(mutations => {
      this.available();
    });
    observer.observe(this.element, {attributes: true, attributeFilter: ['data-variant-available']});
  }

  select(event) {
    let value = event.currentTarget.value || event.currentTarget.dataset.value;
    this.element.setAttribute('data-selected', value);
    if (this.hasLabelTarget) {
      this.labelTarget.textContent = ' ' + this.variantLabel(event.currentTarget);
    }
    this.deselectOptions();
    event.currentTarget.classList.add('active');
  }

  variantLabel(variant) {
    if (variant.tagName == 'SELECT') {
      return variant.options[variant.selectedIndex].dataset.label;
    }
    return variant.dataset.label || variant.textContent
  }

  deselectOptions() {
    this.optionTargets.forEach(option => {
      option.classList.remove('active');
    });
  }

  available() {
    let available = this.data.get('available');
    if (available) {
      this.optionTargets.forEach(target => {
        if (available.indexOf((target.value || target.dataset.value)) < 0) {
          if (target.tagName === 'OPTION') {
            if (!target.classList.contains('unavailable')) {
              target.classList.add('unavailable');
              target.textContent = target.text + ' (unavailable)';
            }
          }
          else {
            target.classList.add('unavailable');
          }
        }
        else {
          if (target.tagName === 'OPTION') {
            if (target.classList.contains('unavailable')) {
              target.classList.remove('unavailable');
              target.textContent = target.dataset.label;
            }
          } else {
            target.classList.remove('unavailable');
          }
        }
      });
    }
  }
}