import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["image", "zoomContainer"];
  initialize() {
    this.createContainer();
    this.createLense();
  }

  createContainer() {
    if (!this.hasZoomContainerTarget) {
      let container = document.createElement("DIV");
      container.setAttribute("class", "md-zoom-container");
    }
  }

  createLense() {
    
  }

}