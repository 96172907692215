import {Controller} from "stimulus";
import Fetch from "../modules/fetch";

export default class extends Controller {
  static targets = ["container"];
  static values = {
    url: String,
    page: Number,
    selector: String
  }

  initialize() {
    this.currentPage = this.startPage;
    this.loadedPages = [this.currentPage];
    this.observer = null;
    this.stopped = false;
    this.createObserver();
    this.observe(this.lastElement);
    this.intersecting = {};

    if (this.startPage > 1) {
      this.observe(this.firstElement);
    }

    //listen for dashboard reset
    document.addEventListener('pager.reset', event => {
      this.reset();
    });

    document.addEventListener('pager.stop', event => {
      this.stop();
    });
  }

  createObserver() {
    this.ticking = false;
    if (!this.observer) {
      this.observer = new IntersectionObserver(this.callback.bind(this), {rootMargin: '0px 0px 600px 0px', threshold:[0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]});
    }
  }

  reset() {
    this.stopped = false;
    this.page = 1;
    this.observe(this.lastElement);
  }

  stop() {
    if (this.stopped) {
      return true;
    }
    this.stopped = true;
  }

  unobserve(element) {
    this.observer.unobserve(element);
  }

  observe(element) {
    if (this.stopped) {
      return;
    }
    this.observer.observe(element);
  }

  callback(entries, observer) {
    let entry = entries[0];
    if (entry.isIntersecting) {
      let result = this.shouldProcess(entry);
      if (result.process) {
        this.getPage(parseInt(result.page));
      }
    }
  }

  shouldProcess(entry) {
    if (this.intersecting.element === entry.target) {
      let direction = this.scrollingDown(entry);
      this.intersecting.y = entry.boundingClientRect.y;
      if (this.intersecting.lastDirection === direction) {
        return {process:false}
      }
      else {
        this.intersecting.lastDirection = direction;
        if (direction) {
          return {process:true, page:parseInt(entry.target.dataset.page)}
        }
        else {
          return {process: true, page: parseInt(entry.target.dataset.page) - 1}
        }
      }
    }
    else {
      this.intersecting = {element:entry.target, y:entry.boundingClientRect.y, lastDirection:null}
      return {process: false}
    }
  }

  scrollingDown(entry) {
    return entry.boundingClientRect.y < this.intersecting.y
  }

  getPage(page) {
    if (!this.pageIsLoaded(page)) {
      if (!this.stopped) {
        this.loadingPage = page;
        new Fetch(this.url + '?page=' + page, this.insertPage.bind(this));
      }
    }
    else {
      this.page = page;
    }
  }

  insertPage(response) {
    if (response.status === 204) {
      return this.stop();
    }
    if (response.status === 200) {
      let isPrevious = (this.loadingPage < this.currentPage);
      this.page = this.loadingPage;
      this.loadedPages.push(this.loadingPage);
      let fragment = document.createRange().createContextualFragment(response.data);
      if (isPrevious) {
        let first = this.element.querySelector(this.selector + ":first-child");
        this.containerTarget.prepend(fragment);
        if (this.page > 1) {
          this.observe(this.firstElement);
        }
        first.scrollIntoView();
      }
      else {
        this.containerTarget.append(fragment);
        this.observe(this.lastElement);
      }
    }
  }

  sendPageView() {
    document.dispatchEvent(new CustomEvent('md.analytics.event', {
      detail: {event: 'pageview', value: window.location.pathname + window.location.search}
    }));
  }

  pageIsLoaded(page) {
    return this.loadedPages.indexOf(page) > -1
  }

  get url() {
    if (this.hasUrlValue) {
      return this.urlValue;
    }
    return this.data.get('url');
  }

  get page() {
    return this.currentPage;
  }

  set page(page) {
    this.currentPage = page;
    history.replaceState(history.state, null, window.location.pathname + "?page=" + this.page);
    this.sendPageView();
  }

  get startPage() {
    let page = this.hasPageValue ? this.pageValue : this.data.get('page');
    if (page != null) {
      return page;
    }
    let match = window.location.search.match(/page=(\d+)/);
    if (match != null) {
      return parseInt(match[1]);
    }
    return 1;
  }

  get lastElement() {
    let element = this.element.querySelectorAll(this.selector + ":last-child")[0];
    element.setAttribute('data-page', this.page + 1);
    return element;
  }

  get firstElement() {
    let element = this.element.querySelectorAll(this.selector + ":first-child")[0];
    element.setAttribute('data-page', this.page);
    return element;
  }

  get selector() {
    return this.selectorValue || 'li';
  }

}