import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  initialize() {
    this.boundClose = this.close.bind(this);
  }

  connect() {
    document.addEventListener('turbo:before-cache', this.boundClose);
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.boundClose);
  }

  open(event) {
    event.preventDefault();
    this.element.classList.toggle('open', true);
    this.startCloseEventListener(this.element);
  }

  close(event) {
    this.element.classList.toggle('open', false);
  }

  toggle(event) {
    event.preventDefault();
    if (this.element.classList.contains('open')) {
      this.close(event);
    } else {
      this.open(event);
    }
  }

  startCloseEventListener(element) {
    const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

    const outsideClickListener = event => {
      if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
        element.classList.toggle('open', false);
        removeClickListener()
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener)
    };

    document.addEventListener('click', outsideClickListener)
  }
}
