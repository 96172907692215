import {Controller} from "stimulus";
import Modal from "../modules/modal";

export default class extends Controller {
  static targets = ["image", "slideshow", "modal"];
  static values = {
    css: String
  }

  initialize() {
    this.modal = null;
    this.boundShow = this.show.bind(this);
  }

  connect() {
    this.element.addEventListener('click', this.boundShow);
  }

  disconnect() {
    this.element.removeEventListener('click', this.boundShow);
  }

  show(event) {
    if (event.target.matches(this.selectors)) {
      let image = event.target.closest(this.selector);
      if (image != null) {
        this.showModal(image);
        if (this.hasSlideshowTarget) {
          let event = new CustomEvent("md.slideshow.slide", { detail: { index: this.imageTargets.indexOf(image) } });
          this.slideshowTarget.dispatchEvent(event);
        }
      }
    }
  }

  showModal(image) {
    if (this.hasModalTarget) {
      this.modalTarget.dispatchEvent(new CustomEvent("md.modal.show"));
    } else {
      new Modal({
        content: this.modalContent(this.imageTargets.indexOf(image)),
        css: this.css,
        target: this.element,
        data: {'image-gallery-target': 'modal'}
      });
    }
  }

  modalContent(index) {
    let container = document.createElement('div');
    container.setAttribute('class', 'slideshow-container image-gallery-slideshow with-dots');
    container.setAttribute('data-controller', 'slideshow');
    container.setAttribute('data-slideshow-lazy', 'true');
    container.setAttribute('data-slideshow-keyboard', 'true');
    container.setAttribute('data-slideshow-mouse', 'true');
    container.setAttribute('data-slideshow-index', index);
    container.setAttribute('data-image-gallery-target', 'slideshow');
    container.innerHTML = '<div class="swiper-container" data-slideshow-target="container"><div class="swiper-wrapper">' + this.slideshowSlides + '</div></div><div class="rotator-arrow prev" data-slideshow-target="prev"></div><div class="rotator-arrow next" data-slideshow-target="next"></div><div class="rotator-page" data-slideshow-target="pager"></div>'
    return container.outerHTML;
  }

  get slideshowSlides() {
    let slides = []
    this.imageTargets.forEach(image => {
      slides.push('<div class="swiper-slide"><img class="swiper-lazy" data-src="' + image.dataset.zoom + '"/><div class="swiper-lazy-preloader"></div><div class="caption">' + image.dataset.caption + '</div></div>');
    });
    return slides.join('');
  }

  get selectors() {
    return '.gallery-asset,.gallery-asset *';
  }

  get selector() {
    return '.gallery-asset';
  }

  get css() {
    return this.cssValue || 'gallery-modal';
  }

}