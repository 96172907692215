import {Controller} from "stimulus";
import Fetch from "../modules/fetch";

export default class extends Controller {
  static targets = ['container', 'content', 'title', 'template']
  static values = {
    url: String,
    auto: Boolean,
    singleLoad: Boolean,
    delay: Number,
    title: String,
    id: String,
    content: String,
    css: String
  }

  initialize() {
    if (!this.hasContainerTarget) {
      this.createModal();
    }
    this.boundOpen = this.show.bind(this);
    this.boundClose = this.hide.bind(this);
    this.loaded = false;
    if (this.autoLoad) {
      if (this.autoLoadDelay > 0) {
        setTimeout(this.show.bind(this), this.autoLoadDelay);
      }
      else {
        this.show();
      }
    }
  }

  connect() {
    this.element.addEventListener('md.modal.show', this.boundOpen);
    this.element.addEventListener('md.modal.hide', this.boundClose);
    document.addEventListener('turbo:before-cache', this.boundClose);
  }

  disconnect() {
    this.element.removeEventListener('md.modal.show', this.boundOpen);
    this.element.removeEventListener('md.modal.hide', this.boundClose);
    document.removeEventListener('turbo:before-cache', this.boundClose);
  }

  load() {
    if (this.loaded || this.url == null) {
      return true;
    }
    new Fetch(this.url, this.updateContent.bind(this));
    return false;
  }

  show() {
    if(this.load()) {
      document.body.classList.add('modal-open');
      this.containerTarget.classList.add('in');
      document.dispatchEvent(new CustomEvent('md.modal.shown'));
    }
  }

  hide() {
    this.containerTarget.classList.remove('in');
    document.body.classList.remove('modal-open');
    document.dispatchEvent(new CustomEvent('md.modal.hidden'));
  }

  remove() {
    this.hide();
  }

  updateContent(response) {
    this.loaded = true;
    if (response.status === 200) {
      this.contentTarget.innerHTML = response.data;
      this.show();
    }
    else {
      this.close();
    }
  }

  createModal() {
    let container = document.createElement('DIV');
    container.setAttribute('aria-labelledby', this.id);
    container.setAttribute('class', this.css);
    container.setAttribute('role', 'dialog');
    container.setAttribute('aria-hidden', 'false');
    container.setAttribute('tabindex', '-1');
    container.setAttribute('data-modal-target', 'container');
    container.innerHTML = this.modalContent;
    this.element.appendChild(container);
  }

  get modalContent() {
    let content = '<div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header"><button aria-label="Close" class="close" data-action="modal#hide" type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor"><path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/></svg></button>'
    content += '<h4 data-modal-target="title" class="modal-title" id="' + this.id + '">' + this.title + '</h4></div>';
    content += '<div class="modal-body" data-modal-target="content">' + this.content + '</div></div></div></div>';
    return content;
  }

  get content() {
    if (this.hasTemplateTarget) {
      return this.templateTarget.innerHTML;
    }
    if (this.contentValue != '') {
      return this.contentValue;
    }
    return '<div class="modal-loading"><div class="modal-loading-spinner"></div></div>';
  }

  get css() {
    let css = ['modal', 'fade'];
    if (this.cssValue != '') {
      css.push(this.cssValue);
    }
    return css.join(' ');
  }

  get title() {
    return this.titleValue;
  }

  get url() {
    if (this.urlValue == '') {
      return null;
    }
    return this.urlValue;
  }

  get id() {
    if (this.idValue == '') {
      this.idValue = 'modal-' + Math.random().toString(36).slice(-5);
    }
    this.idValue;
  }

  get autoLoadDelay() {
    return this.delayValue;
  }

  get autoLoad() {
    let auto = this.autoValue;
    if (this.singleLoadValue) {
      this.autoValue = false;
      this.singleLoadValue = false;
      return this.modalHasLoaded === false;
    }
    return auto;
  }

  get modalHasLoaded() {
    if (this.idValue == '') {
      return false;
    }
    if(sessionStorage.getItem('modal-' + this.idValue) != null) {
      return true;
    }
    let seconds = Math.floor(Date.now() / 1000)
    sessionStorage.setItem('modal-' + this.idValue, seconds);
    return false;
  }
}
