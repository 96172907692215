import {Controller} from "stimulus";
import Modal from "../modules/modal";

export default class extends Controller {
  static targets = ['modal'];
  static values = {
    selector: String,
    css: String
  }
  initialize() {
    this.selectors = this.allSelectors;
    this.boundShow = this.show.bind(this);
  }

  connect() {
    this.element.addEventListener('click', this.boundShow);
  }

  disconnect() {
    this.element.removeEventListener('click', this.boundShow);
  }

  show(event) {
    event.preventDefault();
    if (event.target.matches(this.selectors)) {
      let link = event.target.closest(this.selector);
      if (link != null) {
        event.preventDefault();
        this.showModal(link.href);
      }
    }
  }

  showModal(href) {
    let modal = this.getExistingModal(href);
    console.log('in show modal modal', modal);
    if (modal == null) {
      new Modal({url:href, css:this.css, target:this.element, data:{'modal-content-target':'modal'}});
    }
    else {
      modal.dispatchEvent(new CustomEvent("md.modal.show"));
    }
  }

  getExistingModal(href) {
    let existing = null;
    this.modalTargets.forEach((modal) => {
      console.log('modal.dataset.modalUrlValue', modal.dataset.modalUrlValue);
      if (modal.dataset.modalUrlValue === href) {
        existing = modal;
        console.log('found modal');
      }
    });
    return existing;
  }

  get allSelectors() {
    if (!this.selectorValue) {
      return 'a,a *';
    }
    let selectors = [this.selectorValue, this.selectorValue + ' *']
    return selectors.join(',');
  }

  get selector() {
    return this.selectorValue || 'a'
  }

  get options() {
    let options = {url:this.data.get('url')};
    if (this.cssValue != null) {
      options.css = this.cssValue;
    }
  }
}
