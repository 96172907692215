import {Controller} from "stimulus";

export default class extends Controller {
  static values = {
    selector: String,
    transition: Number
  }

  initialize() {
    this.initialized = false;
    this.setup();
    this.boundLayout = this.layout.bind(this);
  }

  connect() {
    window.addEventListener('resize', this.boundLayout);
    document.addEventListener('md.layout.refresh', this.boundLayout);
  }

  disconnect() {
    window.removeEventListener('md.layout.refresh', this.boundLayout);
    document.removeEventListener('md.layout.refresh', this.boundLayout);
  }

  setup() {
    import("packery").then(packery => {
      this.initialized = true;
      this.packery = new packery.default(this.element, {
        columnWidth: '.grid-sizer',
        itemSelector: this.selector,
        transitionDuration: this.transition,
        percentPosition: true,
        gutter: 0
      });
      this.packery.layout();
    })
  }

  layout() {
    console.log('got a refresh event');
    if (this.initialized) {
      this.packery.layout();
    }
  }

  get selector() {
    return this.selectorValue || 'li'
  }

  get transition() {
    return this.transitionValue || 0;
  }

}